import { CreateRequestFormData } from '@typings/pages/createRequestTypes';
import {
  calculateItem,
  calculateOrderParams,
  createOrderParams,
  CRPageMode,
  DeliveryCompanyItem,
} from '@typings/models/orders';
import { FormInstance } from 'antd';
import { PVZ_CODE, PVZ_INDEX } from '@constants';
import dayjs from 'dayjs';
import { showError } from '@utils/show_error';

const renderPackages = (values: CreateRequestFormData) => {
  const packageCount = Object.keys(values).filter(key => key.includes('package_weight')).length;
  return Array.from({ length: packageCount }, (_, index) => ({
    width: Number(values[`package_width_${index}` as keyof CreateRequestFormData]),
    height: Number(values[`package_height_${index}` as keyof CreateRequestFormData]),
    length: Number(values[`package_length_${index}` as keyof CreateRequestFormData]),
    weight: Number(values[`package_weight_${index}` as keyof CreateRequestFormData]),
  }));
};

export const getProvider = (values: CreateRequestFormData, deliveryTypesList: DeliveryCompanyItem[] | null) => {
  return deliveryTypesList?.find(
    item =>
      item.providerName === values.delivery_type &&
      (!values.delivery_to_type ||
        (item.isPVZ && values.delivery_to_type === 'pvz') ||
        (!item.isPVZ && values.delivery_to_type === 'door')),
  );
};

const additionalValidateDataOnCreate = (values: CreateRequestFormData, form: FormInstance) => {
  const orderNumberError = !values.order_number;
  if (orderNumberError)
    form.setFields([
      {
        name: 'order_number',
        errors: [''],
      },
    ]);

  const paysError = (!values.recipient_pays && !values.sender_pays) || (values.recipient_pays && values.sender_pays);

  const recipientNameError = !values.recipient_name;
  const senderNameError = !values.sender_name;
  const nameErrors = recipientNameError || senderNameError;
  if (nameErrors) {
    if (recipientNameError)
      form.setFields([
        {
          name: 'recipient_name',
          errors: [''],
        },
      ]);

    if (senderNameError)
      form.setFields([
        {
          name: 'sender_name',
          errors: [''],
        },
      ]);
  }

  const recipientPhoneError = !values.recipient_phone || values.recipient_phone?.match(/\d+/g)?.join('')?.length !== 11;
  const senderPhoneError = !values.sender_phone || values.sender_phone?.match(/\d+/g)?.join('')?.length !== 11;

  const phoneErrors = recipientPhoneError || senderPhoneError;

  if (phoneErrors) {
    if (recipientPhoneError) {
      form.setFields([
        {
          name: 'recipient_phone',
          errors: [''],
        },
      ]);
    }

    if (senderPhoneError) {
      form.setFields([
        {
          name: 'sender_phone',
          errors: [''],
        },
      ]);
    }
  }

  if (paysError) {
    form.setFields([
      {
        name: 'sender_pays',
        errors: [''],
      },
      {
        name: 'recipient_pays',
        errors: [''],
      },
    ]);
  }

  if (paysError || nameErrors || phoneErrors || orderNumberError) {
    if (nameErrors || phoneErrors) {
      if (orderNumberError) {
        throw new Error('Заполните данные для отправки и укажите номер заказа');
      }
      throw new Error('Заполните данные для отправки');
    }
    if (paysError) {
      if (orderNumberError) {
        throw new Error('Выберите кто оплачивает доставку и укажите номер заказа');
      }
      throw new Error('Выберите кто оплачивает доставку');
    }
    if (orderNumberError) {
      throw new Error('Укажите номер заказа');
    }
  }
};

const validateData = (
  provider: DeliveryCompanyItem | undefined,
  values: CreateRequestFormData,
  pageMode: CRPageMode,
  form: FormInstance,
) => {
  if (!provider) {
    form.setFields([
      {
        name: 'delivery_type',
        errors: ['Не найден провайдер'],
      },
    ]);
    throw new Error('Не найден провайдер');
  }
  if (!values.city?.data || !values.city?.data?.fias_id || !values.city?.data?.kladr_id) {
    form.setFields([
      {
        name: 'cityVisible',
        errors: ['Выберите город'],
      },
    ]);
    throw new Error('Выберите город');
  }
  if (!values.country?.data) {
    form.setFields([
      {
        name: 'countryVisible',
        errors: ['Выберите страну'],
      },
    ]);
    throw new Error('Выберите страну');
  }

  if (values.delivery_to_type === 'pvz' && !values.pvzCode) {
    form.setFields([
      {
        name: 'delivery_to_type',
        errors: ['Выберите пвз на карте или выберите доставку до двери'],
      },
    ]);
    throw new Error('Выберите пвз на карте или выберите доставку до двери');
  }

  const packageTypeError = !values.package_type && pageMode === CRPageMode.create;
  if (packageTypeError) {
    form.setFields([
      {
        name: 'package_type',
        errors: [''],
      },
    ]);
  }

  const packagesError = !values.packages_size.every(
    packageSize => packageSize.height && packageSize.length && packageSize.weight && packageSize.width,
  );

  if (packagesError) {
    const packageCount = Object.keys(values).filter(key => key.includes('package_weight')).length;
    [...Array(packageCount)].forEach((_, index) => {
      const width_key = `package_width_${index}`;
      const height_key = `package_height_${index}`;
      const length_key = `package_length_${index}`;
      const weight_key = `package_weight_${index}`;

      if (!Number(values[width_key as keyof CreateRequestFormData])) {
        form.setFields([
          {
            name: width_key,
            errors: [''],
          },
        ]);
      }

      if (!Number(values[height_key as keyof CreateRequestFormData])) {
        form.setFields([
          {
            name: height_key,
            errors: [''],
          },
        ]);
      }

      if (!Number(values[length_key as keyof CreateRequestFormData])) {
        form.setFields([
          {
            name: length_key,
            errors: [''],
          },
        ]);
      }

      if (!Number(values[weight_key as keyof CreateRequestFormData])) {
        form.setFields([
          {
            name: weight_key,
            errors: [''],
          },
        ]);
      }
    });
  }

  if (packageTypeError || packagesError) {
    throw new Error('Заполните все поля относящихся к грузам');
  }

  if (pageMode === CRPageMode.create) {
    additionalValidateDataOnCreate(values, form);
  } else {
    form.setFields([
      {
        name: 'order_number',
        errors: undefined,
      },
      {
        name: 'recipient_name',
        errors: undefined,
      },
      {
        name: 'sender_name',
        errors: undefined,
      },
      {
        name: 'recipient_pays',
        errors: undefined,
      },
      {
        name: 'sender_pays',
        errors: undefined,
      },
      {
        name: 'recipient_phone',
        errors: undefined,
      },
      {
        name: 'sender_phone',
        errors: undefined,
      },
    ]);
  }
};

const getPhoneRaw = (phone: string) => phone.replace(/\D/g, '');
const hasField = (field: any, template?: string) => (field ? `${template ? template : ''}${field}` : '');
const getReceiverAddress = (values: CreateRequestFormData) => {
  const indexOrCode = hasField(values.index || values.city.data.postal_code);
  const city = hasField(values.city.data?.city);
  const street = hasField(values.street);
  const house = hasField(values.house, 'д ');
  const apartment = hasField(values.apartment, 'кв ');

  const resultArray = [indexOrCode, city, street, house, apartment].filter(item => item);

  return resultArray.join(', ');
};

export const renderCalculateCostParams = (
  values: CreateRequestFormData,
  deliveryTypesList: DeliveryCompanyItem[] | null,
  form: FormInstance,
  pageMode: CRPageMode,
): calculateOrderParams | undefined => {
  const provider = getProvider(values, deliveryTypesList);
  values.packages_size = renderPackages(values);
  try {
    validateData(provider, values, pageMode, form);
  } catch (e: any) {
    showError(e.message);
    return;
  }
  return {
    addservice: [],
    goods: values.packages_size,
    estimatedCost: values.estimated_cost ? Number(values.estimated_cost) : undefined,
    receiverCityFias: values.city.data.fias_id,
    receiverCityKladr: values.city.data.kladr_id,
    receiverIndex: values.index || values.city.data.postal_code,
    cityname: values.city.data.city,
    receiverAddress: getReceiverAddress(values),
    senderIndex: PVZ_INDEX.toString(), // todo разобраться откуда брать
    provID: provider?.provID as number,
    tariffId: provider?.tariffId as string,
    dateExecute: dayjs().toISOString(),
    // code: values.city.data.code,
    countryCode: values.country.data.code,
    countryCode2: values.country.data.alfa2,
    paymenttype: values.recipient_pays ? 2 : 1, // 1- отправитель, 2 - получатель.
    code: provider?.code as string,
    addDeliveryPrice: Number(values.additional_cost) || 0,
    pvzCode: values.delivery_to_type === 'pvz' ? values.pvzCode || '' : undefined,
    // pvzCode: '32'
    // pvzCode: values.delivery_to_type === 'pvz' ? ((isNaN(values.pvzCode as any) ? values.pvzCode : undefined) || '') : undefined,
  };
};

export const renderCreateDeliveryParams = (
  values: CreateRequestFormData,
  deliveryTypesList: DeliveryCompanyItem[] | null,
  form: FormInstance,
  pageMode: CRPageMode,
  calculatedData: calculateItem,
): createOrderParams | undefined => {
  const provider = getProvider(values, deliveryTypesList);
  if (!provider) throw new Error();
  values.packages_size = renderPackages(values);
  try {
    validateData(provider, values, pageMode, form);
  } catch (e: any) {
    showError(e);
    return;
  }
  return {
    // "userID": "ППризрак",  // todo
    number: '',
    serviceType: calculatedData.tariffId,
    provider: calculatedData.provider,
    priceType: calculatedData.priceType,
    price: calculatedData.priceByCurrency ? Number(calculatedData.priceByCurrency) * 100 : calculatedData.priceByCurrency,
    priceByCurrencyPure: calculatedData.priceByCurrencyPure ? Number(calculatedData.priceByCurrencyPure) * 100 : calculatedData.priceByCurrencyPure,
    paymentType: values.recipient_pays ? 2 : 1,
    cashType: 3,
    // "date": dayjs().format(CD_DATE_FORMAT),
    estimatedCost: values.estimated_cost,
    // "accountProviderName": "",
    // "isReadOnly": true,
    isPVZ: true,
    exTariffId: calculatedData.exTariffid,
    exDeliverytype: '',
    exTariffname: '',
    // "parcelCancel": 0,
    // "shipParcel": 0,
    // "addService": [],
    // "discount": 0,
    // "discountID": 0,
    // "marketPvzID": 0,
    comments: '',
    isExternal: false,
    // "companyCode": "",
    // "resendNumber": "",
    isDanger: false,
    orderNumber: values.order_number,
    exProviderkey: calculatedData.exProviderkey,
    dispatchNumber: '',
    // "isGoodsPrice": true,
    addDeliveryPrice: Number(values.additional_cost) || 0,
    calcUID: calculatedData.uid,
    seller: {
      // "inn": "",
      companyName: 'test',
      contact: {
        phone: getPhoneRaw(values.sender_phone),
        phone1: '',
        email: '',
      },
      address: {
        flat: '',
        house: '',
        street: '',
        city: '',
        cityCode: '44', // todo
        kladr: '',
        fias: '',
        postCode: '',
        pvzCode: '',
        countyCode: '',
        countyCode2: '',
        fullAddress: '',
        isSecondIndex: false,
        country2: '',
        postCode2: '',
        // "city2": ""
      },
    },
    realSeller: {
      phone: getPhoneRaw(values.sender_phone),
      room: '1',
      contactName: values.sender_name,
      // "internalNumber": "",
      // "pureInternalNumber": ""
    },
    packages: values.packages_size.map(packageItem => ({
      size: packageItem,
      items: [
        {
          amount: 1,
          wareKey: values.package_type,
          weight: packageItem.weight,
          comment: values.package_type,
        },
      ],
    })),
    recipient: {
      personFIO: {
        f: values.recipient_name.split(' ')[0],
        n: values.recipient_name.split(' ')[1],
        s: values.recipient_name.split(' ')[2],
      },
      address: {
        flat: values.apartment || '',
        house: values.house,
        street: values.street,
        city: values.cityVisible,
        // "cityCode": "0",
        kladr: values.city.data.city_kladr_id,
        fias: values.city.data.city_fias_id,
        postCode: values.city.data.postal_code,
        pvzCode: values.delivery_to_type === 'pvz' ? values.pvzCode || '' : undefined,
        countyCode: '',
        countyCode2: '',
        fullAddress: `${values.index || values.city.data.postal_code}, ${values.city.data.city}, ${values.street}, д ${
          values.house
        }, кв ${values.apartment}`,
        isSecondIndex: false,
        country2: '',
        postCode2: '',
        city2: '',
      },
      contact: {
        phone: getPhoneRaw(values.recipient_phone),
        // "phone1": "",
        // "email": ""
      },
    },
  };
};
